/* Import custom font */
/* saliec bold */
@font-face {
  font-family: "Sailec Bold";
  src:
    local("Sailec Bold"),
    url("../fonts/Sailec Bold.ttf") format("truetype");
  font-weight: 400;
}

/* saliec medium */
@font-face {
  font-family: "Sailec Medium";
  src:
    local("Sailec Medium"),
    url("../fonts/Sailec Medium.ttf") format("truetype");
  font-weight: 400;
}

/* saliec light */
@font-face {
  font-family: "Sailec Light";
  src:
    local("Sailec Medium"),
    url("../fonts/Sailec Light.ttf") format("truetype");
  font-weight: 400;
}

/* import Google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


/* Import Custom Styles */
@import url("boilerplate.css");
@import url("header.css");
@import url("home.css");

